import React, { useState } from "react";
import Images from "./Images";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useBus from "../customhooks/useBus";
import useStops from "../customhooks/useStops";
import usePendingStudents from "../customhooks/usePendingStudents";
import useStudent from "../customhooks/useStudent";
import Modal from "./Modal";
import TransportRequestForm from "../pages/TransportRequestForm";
import useStaff from "../customhooks/useStaff";

function ShowStudentDetails({
  approval,
  submit,
  studentDetails,
  photo,
  show,
  discount,
  remarks,
  editOrDelete = false,
  college,
  teacher,
}) {
  const [image, setImage] = useState(photo);

  const [error, setError] = useState("");
  const keys = Object.keys(studentDetails);
  const headings = keys.filter(
    (heading) =>
      heading !== "_id" &&
      heading !== "__v" &&
      heading !== "status" &&
      heading !== "adminRemarks"
  );
  const { addPendingStudent, changeAdminProgress } = usePendingStudents();
  const { addStudent, deleteStudent } = useStudent();
  const { addStaff, deleteStaff } = useStaff();
  const loading = useSelector((state) => state.pendingStudentState.loading);
  const anotherLoading = useSelector((state) => state.studentState.loading);
  const navigate = useNavigate();
  const { converIdToBus } = useBus();
  const { convertIdToStop } = useStops();
  const busData = converIdToBus(studentDetails["routeNo"])[0];
  const routeNo = busData ? busData["busName"] : "";
  const stageData = convertIdToStop(studentDetails["stage"])[0];
  const stage = stageData ? stageData["boardingPoint"] : "";

  const handleSubmit = async () => {
    if (validInfo()) {
      studentDetails["file"] = image;
      if (teacher) {
        console.log(studentDetails);
        addStaff(studentDetails);
      } else {
        if (approval || discount === "") {
          studentDetails["status"] = "accepted";
          addStudent(studentDetails);
          if (studentDetails["_id"] !== "") {
            changeAdminProgress(studentDetails, studentDetails["college"]);
          }
        } else {
          studentDetails["remarks"] = remarks;
          studentDetails["status"] = "pending";
          addPendingStudent(studentDetails);
        }
      }
      if (!loading) {
        submit();
        const college = localStorage.getItem("college");
        navigate(
          `/${approval ? "admin" : "adminStaff"}/${
            college ? college : "cmrcet"
          }/home`
        );
      }
    }
  };

  const handleDelete = async () => {
    studentDetails["status"] = "rejected";
    changeAdminProgress(studentDetails, studentDetails["college"]);
    if (!loading) {
      submit();
      const college = localStorage.getItem("college");
      navigate(`/admin/${college ? college : "cmrcet"}/home`);
      // window.location.reload()
    }
  };

  const validInfo = () => {
    return true;
    if (
      studentDetails.stage === "select Stage" ||
      studentDetails.stage === "No stops under this bus"
    ) {
      setError("select proper stage");
    }
    for (const heading of headings) {
      if (studentDetails[heading].length === 0) {
        setError("Add all values to submit form");
        return false;
      }
    }
    // if (!image) {
    //   setError("Add Image");
    //   return false;
    // }
    setError("");
    return true;
  };
  const [isOpen, setOpen] = useState(false);

  function handleEdit() {
    setOpen(true);
  }
  async function handleDeleteStudent() {
    console.log(studentDetails);
    console.log(teacher);
    if (teacher) {
      await deleteStaff(studentDetails);
    } else {
      await deleteStudent(studentDetails);
    }
    const college = localStorage.getItem("college");
    if (!anotherLoading) {
      submit();
      window.location.reload();
    }
  }

  return (
    <>
      <Modal
        open={isOpen}
        onClose={() => {
          window.location.reload();
        }}
      >
        <TransportRequestForm
          college={college}
          data={studentDetails}
          image={photo}
          modal={true}
          teacher={teacher}
        />
      </Modal>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {image === null ? (
          <h5>Upload Image / Wait until uploading the Image</h5>
        ) : (
          <Images
            imageLink={image}
            imageText={"studentPhoto"}
            styles={{ aspectRatio: "1/1", width: "200px", height: "200px" }}
          />
        )}
      </div>
      {headings.map((heading) => (
        <div key={heading} className="inputDataFieldsSingle">
          <h3>{heading[0].toUpperCase() + heading.slice(1, heading.length)}</h3>
          -
          <p
            style={{
              padding: "5px 20px",
              fontSize: "20px",
              borderRadius: "5px",
            }}
          >
            {heading === "routeNo" || heading === "stage"
              ? heading === "routeNo"
                ? routeNo
                : stage
              : studentDetails[heading]}
          </p>
        </div>
      ))}
      {error && <p style={{ color: "red", padding: "10px" }}>{error}</p>}
      <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
        {editOrDelete ? (
          <>
            <button
              onClick={handleEdit}
              style={{ backgroundColor: "grey", color: "white" }}
            >
              Edit
            </button>

            <button
              onClick={handleDeleteStudent}
              style={{ backgroundColor: "red", color: "white" }}
            >
              Delete
            </button>
          </>
        ) : (
          show &&
          (approval ? (
            <button
              onClick={handleSubmit}
              style={{ backgroundColor: "green", color: "white" }}
            >
              Accept
            </button>
          ) : (
            <button onClick={handleSubmit}>Send Request</button>
          ))
        )}
        {approval && (
          <button
            onClick={handleDelete}
            style={{ backgroundColor: "red", color: "white" }}
          >
            Reject
          </button>
        )}
      </div>
    </>
  );
}

export default ShowStudentDetails;
